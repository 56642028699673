@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "GoldmanBold";
    src: local("GoldmanBold"),
        url("./fonts/CiutadellaRounded/CiutadellaRounded-Rg.ttf") format("truetype");
}

@layer base {
    h1 {
      @apply text-4xl 
    }
    h2 {
      @apply text-2xl
    }
    h3 {
      @apply text-xl
    }
    h4 {
      @apply text-base;
    }
    h5 {
      @apply text-sm;
    }
    h6 {
      @apply text-xs;
    }
    a {
      @apply text-blue-600 underline;
    }
  }
  
iframe {
    pointer-events: none;
}

body {
    font-family: "GoldmanBold";
    font-size: 18px;
    font-weight: 600;
}

.border-1 {
    border-width: 1px;
}

.standard-box-shadow {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.codex-editor {
  font-weight: 100;
}

.w-32 {
    width: 32%;
}

.w-9 {
    width: 9%;
}

.w-7 {
    width: 7%;
}

.w-10 {
    width: 10%;
}

.w-15 {
    width: 14%;
}

.w-20 {
    width: 20%;
}

.w-25 {
    width: 25%;
}

.w-30 {
    width: 30%;
}

